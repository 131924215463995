import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Logo from '~/components/Layout/Navigation/Logo';
import Login from '~/containers/Login';

const PageLogin: FC<PageProps> = ({ location }) => (
  <div className="flex min-h-main flex-col items-center justify-center md:p-24">
    <div className="mx-auto w-full max-w-md">
      <div className="mb-12 flex w-full justify-center">
        <Logo width="140px" />
      </div>
      <Login href={location.href} />
    </div>
  </div>
);

export default PageLogin;
